import React, { Component } from 'react';
import headerImg from './assets/images/space-photo.jpeg';
import MenuOptions from './MenuOptions';
import Display from './Display';
import downArrow from './assets/images/down-chevron.svg';

import './App.scss';


var height = window.innerHeight
|| document.documentElement.clientHeight
|| document.body.clientHeight; 

let bannerHeight = height -200;
const headerDivStyle = {
  color: 'blue',
  backgroundImage: 'url('+ headerImg + ')',
  backgroundSize: 'cover',
  height: '100%',
  minHeight: bannerHeight,
  position: 'relative'
};
const headerTopText = {
  color: 'white',
  padding: '50px',
  fontFamily: 'arial, helvetica',
  fontSize: '22px'

};
const headerTitle = {
  color: 'white',
  margin: 'auto',
  fontFamily: 'arial, helvetica',
  fontSize: '56px',
  width: '100%',
  paddingTop: '12%',
  paddingBottom: '22%'

};
const headerArrowStyle = {
  color: 'white',
  height: '20px',
  width: '100%',
  textAlign: 'center',
  position: 'relative',
  bottom: '70px'

};
const viewerStyle = {
  opacity:1
};
const whiteBG = {
  backgroundColor: 'white'
};




class App extends Component {

  scrollToContent(e){
    window.scroll({
      top: e.clientY+120, 
      left: 0, 
      behavior: 'smooth'
    });
  }
  returnToTop(){
    window.scroll({
      top: 460, 
      left: 0, 
      behavior: 'smooth'
    });
  }
  returnBadge(e){
    e.target.scroll({
      top: 0, 
      left: 0
    });
    e.target.style.overflow = 'hidden';
  }
  scrollForDetails(e){
    e.target.style.overflow = 'auto';
  }

  constructor(props){
    super(props);
    this.applyChanges = this.applyChanges.bind(this);

    this.state = {
      launchesJSON: [],
      launchepadJSON: [],
      keywords: '',
      launchpad: 'any',
      minyear: 'any',
      maxyear: 'any',
      daterange: false

    }
  }


  lastCheck(){
    if(document.getElementById("flightListings").innerHTML.indexOf("#")>=0) {
      document.getElementById("noresults").style.display = 'none'; 
    } else { 
      document.getElementById("noresults").style.display = 'inline';
    }
  }


  showCount(){
    let content = document.getElementById("flightListings").innerHTML;
    let num = content.split("#");
    let numlength = num.length - 1;
    document.getElementById("resultcount").innerHTML = numlength;
    setTimeout(() => { this.lastCheck() }, 100);
    if(numlength===1){
      document.getElementById("plural").innerHTML = "Mission";
    } else {
      document.getElementById("plural").innerHTML = "Missions";
    }
    if(numlength > 2){
      document.getElementById("totop").style.display = 'inline';
    } else {
      document.getElementById("totop").style.display = 'none';
    }
  }

  applyChanges(){ 

    this.setState({
      keywords: document.getElementById("keywordBox").value,
      launchpad: document.getElementById("launchpadMenu").value,
      minyear: document.getElementById("minYearMenu").value,
      maxyear: document.getElementById("maxYearMenu").value
    })
    }

    componentWillMount(){   

      fetch('http://launchpad.api.dev.stevs.net/launches.json')
      .then(res => res.json())
      .then((launches) => {
        this.setState({ launchesJSON: launches })
      })
      .catch(console.log)


    }

    componentDidMount(){
      this.showCount();
    }

    componentDidUpdate(){
      this.showCount();
      document.getElementById("flightListings").style.opacity = "0.0";

      // uncomment below to add a 'db search lookup' type behaviour

      // document.getElementById("flightListings").style.height = "300px";
      // document.getElementById("flightListings").style.overflow = "hidden"; 

      document.getElementById("spinner").style.display = "inline-block";
      setTimeout(() => { this.fullOpacity() }, 1200);
    }

    fullOpacity(){
      document.getElementById("flightListings").style.height = "";
      document.getElementById("flightListings").style.overflow = "auto";
      document.getElementById("flightListings").style.opacity = "1";
      document.getElementById("spinner").style.display = "none";
    }




  render() {



    let { launchesJSON } = this.state;
    let theJSON = launchesJSON;
    let kw = this.state.keywords;
    let keywordJSON = launchesJSON.filter(item => 
        item.rocket.rocket_name.includes(kw) || 
        item.payload[0].payload_id.includes(kw) || 
        item.flight_number.toString() === kw);
    let takeoffJSON = launchesJSON.filter(item => item.launch_site.site_id.includes(this.state.launchpad));


     if(kw==="" && 
        this.state.launchpad==="any" && 
        this.state.minyear==="any" && 
        this.state.maxyear==="any") {

        theJSON = launchesJSON;
    } else { 
     if(this.state.launchpad!=="any") {
         theJSON = takeoffJSON;
         if(kw!=="") {
          let newkeywordJSON = takeoffJSON.filter(item => 
          item.rocket.rocket_name.includes(kw) || 
          item.flight_number.toString() === kw || 
          item.payload[0].payload_id.includes(kw));
          theJSON = newkeywordJSON;
        }

     } else { theJSON = launchesJSON }
  }


if(kw!=="") {
  theJSON = keywordJSON;
} 
if(kw!=="" && this.state.launchpad!=="any") {
  let takeoffKeywordJSON = takeoffJSON.filter(item => 
        item.rocket.rocket_name.includes(kw) || 
        item.flight_number.toString() === kw || 
        item.payload[0].payload_id.includes(kw));
  theJSON = takeoffKeywordJSON;
} 

    var arr = [];
    Object.keys(theJSON).forEach(function(key) {
      arr.push(theJSON[key]);      
    });




        return ( 
          <div className="App">
            <div className="width100pc ">
                <div className="row" style={headerDivStyle}> 
                  <div style={headerTopText}> SPACE SAVVY</div>
                  <div style={headerTitle}> Discover Space Missions</div>
                <div className="arrowDiv" style={headerArrowStyle}> <img id="white-arrow" onClick={this.scrollToContent} src={downArrow} width="30" height="30" alt="" /> </div>
                </div>
                <div className="greyBG">
                  <div className="content-container">
                  <MenuOptions applyChanges={this.applyChanges} />
                                  <div id="spinner">
                                  <div className="wrap">
                                    <div className="loading">
                                      <div className="bounceball"></div>
                                      <div className="text">NOW LOADING</div>
                                    </div>
                                  </div>
                                </div>
                    <div style={whiteBG}><div id="flightListings" className="view-area" style={viewerStyle}>
                      <br />
                      <br />
                      Showing <span id="resultcount"></span> <span id="plural">Missions</span>
                      {arr.map((item,i) => {
                          let launchday = parseInt(item.launch_date_local)
                          let daterange = false;
                            //this.checkDates = (daterange, launchday)
                            if(this.state.minyear==="any" && this.state.maxyear==="any") { daterange = true; } else
                            if(this.state.minyear!=="any" && this.state.maxyear==="any") { if(launchday >= this.state.minyear) { daterange = true; }} else
                            if(this.state.minyear==="any" && this.state.maxyear!=="any") { if(launchday <= this.state.maxyear) { daterange = true; }} else
                            if(this.state.minyear!=="any" && this.state.maxyear!=="any") { if(launchday >= this.state.minyear && launchday <= this.state.maxyear){ daterange = true;}}

                        if(daterange === true)
    
                      return <Display key={i} 
                                applyChanges={this.applyChanges}
                                returnBadge={this.returnBadge}
                                scrollForDetails={this.scrollForDetails}
                                flightnumber={item.flight_number}
                                rocket={item.rocket.rocket_name} 
                                payload={item.payload[0].payload_id} 
                                missionbadge={item.links.mission_patch} 
                                launchpad={item.launch_site.site_id} 
                                launchdate={item.launch_date_local} 
                                landed={item.land_success ? '' : ' - Failed Mission'} 
                                redditcampaign={item.links.reddit_campaign} 
                                reditlaunch={item.links.reddit_launch} 
                                redditmedia={item.links.reddit_media} 
                                presskit={item.links.presskit} 
                                article={item.links.article_link} 
                                video={item.links.video_link}   
                                minyear={this.state.minyear} 
                                maxyear={this.state.maxyear}     
                                daterange={this.state.daterange}      
                                details={item.details}             
                                />
                        return null;
                      })}
                      <div id="noresults" className="hdn nr-size"><h1 align="center"><br /><br /><br />no results sorry...<br /><br /><br /><br /></h1></div>
                      </div>
                      </div>
                  </div>
                </div>
                <div className="row"><div className="col-6">&copy; SPACE SAVVY</div><div id="totop" className="col-6 baseLnk" onClick={this.returnToTop}>To the top</div></div>
                <div className="row">&nbsp;</div>
              </div>
            </div>
          )
      }
}

export default App;
