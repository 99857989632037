import React, { Component } from 'react';

  
  
  export default class MenuOptions extends Component {
    constructor(props){
      super(props);
      
      this.state = {
        launchpadJSON: [],
        keywords: '',
        launchpad: 'any',
        minyear: 'any',
        maxyear: 'any'
      };
  } 



  
  updateSearchCriterea = (e) => {
    if(e.target.id==="keywordBox"){
      this.setState({
        keywords: e.target.value
        })
    } 
    else if(e.target.id==="launchpadMenu"){
      this.setState({
        launchpad: e.target.value
        })
    } 
    else if(e.target.id==="minYearMenu"){
      this.setState({
        minyear: e.target.value
        })
    } 
    else if(e.target.id==="maxYearMenu"){
      this.setState({
        maxyear: e.target.value
        })
      }
      if(e.keyCode === 13 || e.which === 13) {
        document.getElementById("clickingApply").click();
      }
      
  }
  


  componentWillMount(){   
  
      fetch('http://launchpad.api.dev.stevs.net/launchpads.json')
      .then(res => res.json())
      .then((launchpads) => {
        this.setState({ launchpadJSON: launchpads })
      })
      .catch(console.log)
  }
  
  componentDidUpdate(){
    let btnA = document.getElementById("clickingApply");
        btnA.disabled = false;
        btnA.classList.remove("waitCursor");
        btnA.classList.add("proceedCursor");
    setTimeout(() => { this.noBadDates() }, 100);
  }



  noBadDates(){
    let btnA = document.getElementById("clickingApply");
    let minY = document.getElementById("minYearMenu");
    let maxY = document.getElementById("maxYearMenu")
    if(minY.value!=="any"){ 
      if(maxY.value!=="any"){
        if(minY.value > maxY.value){
          alert("Please check the date")  
          btnA.disabled = true;
          btnA.classList.remove("proceedCursor");
          btnA.classList.add("waitCursor");        
        } 
      }
    } else {
      btnA.classList.remove("waitCursor");
      btnA.classList.add("proceedCursor");
      btnA.disabled = false;
    }
  }


    render() {
  
  
      return ( 
        <div>
          <div id="fullWidthMenu" className="row pad20">
    <div  id="leftmenu" className="col">
            <div className="col-6 left iB">
              <div>Keywords</div> 
              <div><input id="keywordBox" onKeyUp={this.updateSearchCriterea } className="w100pc h50" placeholder="eg: Falcon" /></div>
            </div>
            <div className="col-5 left iB">
              <div>Launch&nbsp;Pad</div> 
                <div>
    
                        {['full_name'].map(key => (
                          <select id="launchpadMenu" className="w100pc h50" key={key} onChange={this.updateSearchCriterea}>
                            <option value="any">Any</option>
                            {this.state.launchpadJSON.map(({ [key]: value, id }) => <option key={value} value={id}>{value}</option>)}
                          </select>
                        ))}
    
                </div>
              </div>
    </div>
    <div id="rightmenu" className="row linebreak">
              <div className="col-4 left iB">
              <div>Min&nbsp;Year</div> 
              <div><select id="minYearMenu" className="w100pc h50" onChange={this.updateSearchCriterea}>
                      <option value="any">Any</option>  
                        <option value="2006">2006</option>
                        <option value="2007">2007</option>
                        <option value="2008">2008</option>
                        <option value="2009">2009</option>
                        <option value="2010">2010</option>
                        <option value="2012">2012</option>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                    </select></div>
              </div>
              <div className="col-4 left iB">
              <div>Max&nbsp;Year</div> 
              <div><select id="maxYearMenu" className="w100pc h50" onChange={this.updateSearchCriterea}>
                      <option value="any">Any</option>
                        <option value="2006">2006</option>
                        <option value="2007">2007</option>
                        <option value="2008">2008</option>
                        <option value="2009">2009</option>
                        <option value="2010">2010</option>
                        <option value="2012">2012</option>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                    </select></div>
              </div>
              <div className="col-4 left iB">
              <div>&nbsp;</div> 
              <div><button id="clickingApply" onClick={this.props.applyChanges } className="btn applyBtn proceedCursor">Apply</button></div>
            </div>
          </div>
    </div>
        </div>
      );
    }
  }
  
  
  
  