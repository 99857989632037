import React, { Component } from 'react';




export default class Display extends Component {
  
    constructor(props) {
        super(props);
    
        this.state = {

            rocket: null,
            payload: null,
            missionbadge: null,
            launchdate: null,
            launchtime: null,
            redditcampaign: null,
            reditlaunch: null,
            redditmedia: null,
            presskit: null,
            article: null,
            video: null,
            launchsuccess: null,
            details: null
        }
    }

    handleClick = (here) => {
        window.open(here,'_blank');
    }


    formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }

    render() {
        let takeoff='';
        let thisdate='';





        if(this.props.launchpad === "ksc_lc_39a") { takeoff = 'Kennedy Space Center Launch Complex 39A' } else
        if(this.props.launchpad === "kwajalein_atoll") { takeoff = 'Kwajalein Atoll' } else
        if(this.props.launchpad === "ccafs_slc_40") { takeoff = 'Cape Canaveral Air Force Station Space Launch Complex 40' } else
        if(this.props.launchpad === "ccafs_lc_13") { takeoff = 'Cape Canaveral Air Force Station Space Launch Complex 13' } else
        if(this.props.launchpad === "vafb_slc_3w") { takeoff = 'Vandenberg Air Force Base Space Launch Complex 3W' } else
        if(this.props.launchpad === "vafb_slc_4e") { takeoff = 'Vandenberg Air Force Base Space Launch Complex 4E' } else
        if(this.props.launchpad === "vafb_slc_4w") { takeoff = 'Vandenberg Air Force Base Space Launch Complex 4W' } else
        if(this.props.launchpad === "stls") { takeoff = 'SpaceX South Texas Launch Site'}


        let i='';
        let theDay='';
        let theMonth='';
        let theYear='';
        thisdate = this.props.launchdate;
        theYear = parseInt(thisdate);

        for(i=5;i<=6;i++){
            theMonth += thisdate.charAt(i)
        }
        let monthName = '';
            if(theMonth==="01"){monthName="January"} else
            if(theMonth==="02"){monthName="February"} else
            if(theMonth==="03"){monthName="March"} else
            if(theMonth==="04"){monthName="April"} else
            if(theMonth==="05"){monthName="May"} else
            if(theMonth==="06"){monthName="June"} else
            if(theMonth==="07"){monthName="July"} else
            if(theMonth==="08"){monthName="August"} else
            if(theMonth==="09"){monthName="September"} else
            if(theMonth==="10"){monthName="October"} else
            if(theMonth==="11"){monthName="November"} else
            if(theMonth==="12"){monthName="December"} 

        for(i=8;i<=9;i++){
            theDay += thisdate.charAt(i)
        }
        let dSuffix = '';
            if(theDay==="01") {dSuffix = "1st"}
            if(theDay==="02") {dSuffix = "2nd"}
            if(theDay==="03") {dSuffix = "3rd"}
            if(theDay==="04") {dSuffix = "4th"}
            if(theDay==="05") {dSuffix = "5th"}
            if(theDay==="06") {dSuffix = "6th"}
            if(theDay==="07") {dSuffix = "7th"}
            if(theDay==="08") {dSuffix = "8th"}
            if(theDay==="09") {dSuffix = "9th"}
            if(theDay==="10") {dSuffix = "10th"}
            if(theDay==="11") {dSuffix = "11th"}
            if(theDay==="12") {dSuffix = "12th"}
            if(theDay==="13") {dSuffix = "13th"}
            if(theDay==="14") {dSuffix = "14th"}
            if(theDay==="15") {dSuffix = "15th"}
            if(theDay==="16") {dSuffix = "16th"}
            if(theDay==="17") {dSuffix = "17th"}
            if(theDay==="18") {dSuffix = "18th"}
            if(theDay==="19") {dSuffix = "19th"}
            if(theDay==="20") {dSuffix = "20th"}
            if(theDay==="21") {dSuffix = "21st"}
            if(theDay==="22") {dSuffix = "22nd"}
            if(theDay==="23") {dSuffix = "23rd"}
            if(theDay==="24") {dSuffix = "24th"}
            if(theDay==="25") {dSuffix = "25th"}
            if(theDay==="26") {dSuffix = "26th"}
            if(theDay==="27") {dSuffix = "27th"}
            if(theDay==="28") {dSuffix = "28th"}
            if(theDay==="29") {dSuffix = "29th"}
            if(theDay==="30") {dSuffix = "30th"}
            if(theDay==="31") {dSuffix = "31st"}

            let flighttime = '';
            for(i=11;i<=15;i++){
                flighttime += thisdate.charAt(i)
            }

      return ( 
        <div>
            <div className="row pad20 mw500 bdrBottom">

              <div className="col-3">
            { 
            (this.props.details)
                    ?   <div className="card" onMouseOver={this.props.scrollForDetails}  onMouseOut={this.props.returnBadge}> 
                            <div className="imgBx">
                                <img src={ this.props.missionbadge } width="150" height="150" alt="" />
                            </div>
                            <div className="details">                    
                                {
                                        this.props.details
                                }
                            </div>
                        </div> 
                    : 
                    <img src={ this.props.missionbadge } width="150" height="150" alt="" />
                    }
              </div>

              <div className="col-6 text-left ">
                <br />
                <h5>{this.props.rocket} - {this.props.payload} <span className="red-text">{ this.props.landed}</span></h5>
                <p className="standard">
                  Launched <strong>{ dSuffix +' '+monthName +' '+ theYear }</strong> at <strong>{ flighttime }</strong> from <strong>{ takeoff }</strong>.
                </p>
                { 
                    (this.props.redditcampaign)
                    ? <button className="linkBtn" onClick={() =>  this.handleClick(this.props.redditcampaign)} > Reddit Campaign </button> 
                    : null 
                }
                { 
                    (this.props.redditlaunch)
                    ? <button className="linkBtn" onClick={() =>  this.handleClick(this.props.redditlaunch)}> Reddit Launch </button> 
                    : null 
                }
                { 
                    (this.props.redditcmedia)
                    ? <button className="linkBtn" onClick={() =>  this.handleClick(this.props.redditcmedia)}> Reddit Media </button> 
                    : null 
                }
                { 
                    (this.props.presskit)
                    ? <button className="linkBtn" onClick={() =>  this.handleClick(this.props.presskit)}> Press Kit </button> 
                    : null 
                }
                { 
                    (this.props.article)
                    ? <button className="linkBtn" onClick={() =>  this.handleClick(this.props.article)}> Article </button> 
                    : null 
                }
                { 
                    (this.props.video)
                    ? <button className="linkBtn" onClick={() =>  this.handleClick(this.props.video)}> Watch Video </button> 
                    : null 
                }
              </div>
              <div className="col-2">
                <div className="flightNumber">#{this.props.flightnumber}</div>
                <div className="fnTxt">FLIGHT NUMBER</div>
              </div>
            </div>
        </div>
        );
      }
    }
  